*{
  font-family: "Open Sans", sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing:border-box;
}
.App {
  max-width:1440px;
  margin:auto;
}


html {
  scroll-behavior: smooth;
}



.s1{
  font-weight:400;
  font-size:15px;
  line-height: 24px;
  letter-spacing: 0px;
  @media screen and (min-width:768px){
    font-size:18px;
    line-height: 32px;

  }
}

.d1{
  font-weight:700;
  font-size:22px;
  line-height: 32px;
  letter-spacing: 0px;
  @media screen and (min-width:768px){
   
    font-size:32px;
    line-height: 56px;
   

  }
}

.h2{
  font-weight:400;
  font-size:16px;
  line-height: 24px;
  letter-spacing: 0px;
  @media screen and (min-width:768px){
    font-weight:400;
  font-size:20px;
  line-height: 40px;
  

  }
}

.a2{
  font-weight:400;
  font-size:12px;
  line-height: 20px;
}

.s2{
  font-weight:400;
  font-size:14px;
  line-height: 24px;
  letter-spacing: 0px;
  @media screen and (min-width:768px){
    font-size:16px;
    line-height: 30px;

  }
}

.h1{
  font-weight:600;
  font-size:18px;
  line-height: 24px;
  letter-spacing: 0px;
  @media screen and (min-width:768px){
    font-weight:500;
    font-size:22px;
    line-height: 48px;
  }
}

.a5{
  font-weight:400;
  font-size:18px;
  line-height: 32px;
}

.b1{
  font-weight:400;
  font-size:13px;
  line-height: 24px;
  letter-spacing: 0px;
  @media screen and (min-width:768px){

    font-size:14px;
  line-height: 24px;
  }
}

.d2{
  font-weight:600;
  font-size:20px;
  line-height: 32px;
  letter-spacing:0px;
  @media screen and (min-width:768px){
    font-size:24px;
    line-height: 48px;

  }
}


.a1{
  font-weight:400;
  font-size:13px;
  line-height: 24px;
  letter-spacing:0px;

}

.a2{
  font-weight:400;
  font-size:12px;
  line-height: 20px;
  letter-spacing:0px;

}
.a3{
  font-weight:400;
  font-size:11px;
  line-height: 18px;
  letter-spacing:0px;

}
.a4{
  font-weight:400;
  font-size:10px;
  line-height: 16px;
  letter-spacing:0px;

}
.a5{
  font-weight:600;
  font-size:13px;
  line-height: 24px;
  letter-spacing:0px;

}

.a6{
  font-weight:600;
  font-size:10px;
  line-height: 16px;
  letter-spacing:0px;

}